.myportfolio__screen {
  display: grid;
  justify-content: center;
  background-color: rgba(19, 25, 42, 0.8);
  grid-template-columns: 1fr repeat(12, [col-start] 80px) 1fr;
  min-height: 100%;
  gap: 20px;

  &__cover__photo__container {
    grid-column: 2 / 14;
    height: 18.8rem;
    position: relative;

    &-cover__image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      object-fit: cover;
      background-position: center;
      width: 100%;
      height: 100%;
      border-bottom-right-radius: 2rem;
      border-bottom-left-radius: 2rem;
    }
    &-image {
      position: absolute;
      padding: 0 24rem;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__user__info__container {
    grid-column: 2 / 14;
    background-color: #040508;
    border-radius: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    &__avatar__container {
      height: 8.8rem;
      width: 8.8rem;
      border-radius: 100%;
      background: #cc7638;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 3rem 3.6rem;

      &-text {
        font-size: 3.2rem;
        font-weight: 800;
        line-height: 5.3rem;
      }
    }

    &__basic__container {
      &__name__content {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-bottom: 1.6rem;

        &-name {
          color: #f4f8fb;
          font-size: 3.2rem;
          font-weight: 600;
          letter-spacing: -0.89px;
          line-height: 3.9rem;
          padding-right: 1.3rem;
        }

        &-username {
          opacity: 0.45;
          color: #f4f8fb;
          font-size: 1.4rem;
          font-weight: 300;
          letter-spacing: -0.39px;
          line-height: 1.7rem;
        }
      }

      &__land__content {
        display: flex;
        flex-direction: row;
        align-items: flex-end;

        &-name {
          opacity: 0.6;
          color: #f4f8fb;
          font-size: 1.5rem;
          font-weight: 300;
          letter-spacing: -0.42px;
          line-height: 1.9rem;
          padding-left: 0.4rem;
          padding-right: 3.2rem;
        }

        &-lands {
          color: #f4f8fb;
          font-size: 2.2rem;
          font-weight: 600;
          letter-spacing: -0.61px;
          line-height: 2.6rem;
        }

        &-tiles {
          color: #7d9aff;
          font-size: 2.2rem;
          font-weight: 600;
          letter-spacing: -0.61px;
          line-height: 2.6rem;
        }

        &-priceValue {
          color: #46ff6f;
          font-size: 2.2rem;
          font-weight: 600;
          letter-spacing: -0.61px;
          line-height: 2.6rem;
        }
      }
    }
  }

  &__lands__container {
    grid-column: 2 / 14;

    &-title {
      font-size: 3.2rem;
      font-weight: 600;
      letter-spacing: -0.89px;
      line-height: 3.9rem;
      padding-bottom: 1.7rem;
    }

    &__content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  &__pagination__container {
    grid-column: 2/ 14;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 2rem;
    max-height: 5.6rem;
  }
}
