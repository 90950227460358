.geocoder {
  background-color: #14171f;

  input {
    background: #14171f;
    border: 1px solid #466bff;
    border-radius: 10px;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: white;
      font-size: 1.4rem;
      font-weight: 200;
      line-height: 1.7rem;
    }
  }

  input[type='text'] {
    color: white;
    font-size: 1.4rem;
    font-weight: 200;
    line-height: 1.7rem;
  }
  div {
    button {
      background-color: #14171f;
    }
  }

  .mapboxgl-ctrl-geocoder {
    background: transparent;
  }

  svg {
    fill: white;
  }
}

.map__screen {
  display: flex;
  flex-direction: column;

  &__header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1.6rem;
    background-color: #14171f;
    height: 5rem;
  }

  &__content {
    display: flex;
    flex-direction: row;
    // flex: 1;
    height: calc(100vh - 13.8rem);

    &__pagination__container {
      display: flex;
      justify-content: center;
      padding: 2rem 0;
    }

    &__marketplace__container {
      flex: 1;
      display: flex;
      flex-direction: column;
      background-color: #0d0f14;
      overflow-y: scroll;

      &__table {
        &__header {
          display: flex;
          flex-direction: row;
          max-height: 9rem;
          padding: 0 0.8rem;
        }

        &__col {
          flex: 1;
          display: flex;
          border-bottom: 1px solid #1b233b;
          flex-direction: column;

          justify-content: center;
          align-items: center;

          &-value {
            font-size: 1.4rem;
            letter-spacing: 0.1px;
            text-align: center;
            padding: 1.2rem 0;
          }

          &-btn {
            height: 3rem;
            font-size: 1.4rem;
            margin-bottom: 0.5rem;
            margin-right: 1rem;
            margin-top: 0.5rem;
          }

          &-black-btn {
            background: none;
            border: 1.8px solid #3859cc;
          }

          &-text {
            color: #92929d;
            font-size: 1.4rem;
            font-weight: 600;
            letter-spacing: 0.1px;
            line-height: 1.7rem;
            text-align: center;
            padding: 1.2rem 0;
          }
        }
      }
    }

    &__map__container {
      flex: 2;
    }
  }
}

.sidebar {
  background-color: rgba(35, 55, 75, 0.9);
  color: #ffffff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  top: 10;
  left: 0;
  margin: 12px;
  border-radius: 4px;
}

.selectionInfo {
  position: absolute;
  z-index: 1;
  width: 300px;
  border: 1px solid #111620;
  border-radius: 20px;
  background-color: #070c18;
  box-shadow: 0 6px 12px -6px rgba(0, 0, 0, 0.3);
  right: 16px;
  margin-top: 16px;
  padding: 14px 16px;
}

.selectionInfoHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.mapContainer p {
  display: inline-block;
}

.selectionInfoText {
  color: #ffffff;
  font-family: 'Inter V' sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
}

.clear {
  color: #d32c2c;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
  cursor: pointer;
}

.tilesSelectedContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tilesSelected {
  display: flex;
  align-items: center;
}

.tilesSelectedText {
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
  margin-left: 5px;
}

.tilesSelectedNumber {
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
}

.devider {
  height: 1px;
  width: 100%;
  opacity: 0.2;
  background-color: #d8d8d8;
  margin-top: 13px;
  margin-bottom: 8px;
}

.tileInfo {
  display: flex;
  flex-direction: column;
}

.tileInfoHeader {
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
}

.tileInfoText {
  opacity: 0.5;
  color: #ffffff;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 24px;
}

.priceContainer {
  padding: 9px 0;
  background-color: #12161f;
  margin: 0 -16px;
  padding: 0 16px;
  padding-top: 1px;
  padding-bottom: 9px;
}

.priceRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}

.priceRowText {
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
}

.priceRowNumber {
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
}

.buy {
  height: 40px;
  margin-top: 16px;
}
