.buttonContainer {
  height: 5rem;
  border-radius: 1.5rem;
  background: linear-gradient(231.98deg, #46abff 0%, #466aff 100%);
  border: none;
  width: 100%;
  color: #ffffff;
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 2.1rem;
  text-align: center;
  cursor: pointer;
}

.loginBtn {
  height: 4rem;
  padding: 1rem 6rem;
  border-radius: 1.4rem;
  background: linear-gradient(231.98deg, #46abff 0%, #466aff 100%);
}

.disabled {
  opacity: 0.54;
}
