.profile__screen {
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr repeat(12, [col-start] 80px) 1fr;
  width: 100%;
  min-height: 100%;
  gap: 20px;
  background-color: #151b2e;

  &__main__container {
    grid-column: 2 / -2;

    &__content {
      display: flex;
      flex-direction: row;
      padding: 2rem 0;

      &__left {
        flex: 1;
        border-right: 1px solid #202945;
        margin-left: 4rem;
        padding-right: 6rem;

        &-input {
          margin-bottom: 2rem;
          input.dirty + .placeholder,
          input:focus + .placeholder,
          input:not(:placeholder-shown) + .placeholder {
            background-color: #151b2e;
          }
        }

        &-referral__code__input {
          margin-bottom: 2rem;
          margin-top: 2rem;
          input.dirty + .placeholder,
          input:focus + .placeholder,
          input:not(:placeholder-shown) + .placeholder {
            background-color: #151b2e;
            color: #ffffff;
          }

          input.dirty,
          input:focus,
          input:not(:placeholder-shown) {
            border: 1px solid #ffffff;
          }
        }

        &-reset__password_btn {
          background: transparent;
          border: 1px solid #466fff;
          height: 4rem;
          font-size: 1.4rem;
          margin-bottom: 3rem;
        }

        &-copy__code__btn {
          background: transparent;
          border: 1px solid #ffffff;
          height: 4rem;
          font-size: 1.4rem;
          margin-bottom: 4rem;
        }

        &__image__container {
          display: flex;
          margin: 3rem 0;

          &-avatar {
            height: 8.8rem;
            width: 8.8rem;
            border-radius: 100%;
            background: #cc7638;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          &-text {
            font-size: 3.2rem;
            font-weight: 800;
            line-height: 5.3rem;
          }

          &-profile__photo {
            width: 100%;
            height: 100%;
            border-radius: 100%;
            object-fit: fill;
          }
        }

        &__name__container {
          padding-left: 2rem;

          &-text {
            color: #f4f8fb;
            font-size: 3.2rem;
            font-weight: 600;
            padding-bottom: 1rem;
          }

          &-edit__photo {
            color: #f4f8fb;
            font-size: 1.4rem;
            font-weight: 500;
            text-decoration: underline;

            &:hover {
              cursor: pointer;
            }
          }
        }
      }

      &__right {
        flex: 1;
        margin-left: 4rem;
      }
    }
  }
}
