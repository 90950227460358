.home__screen {
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr repeat(12, [col-start] 80px) 1fr;
  gap: 20px;

  &__header {
    grid-column: 1 / -1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 8rem;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      width: 0px;
      background: transparent; /* make scrollbar transparent */
    }

    &__card {
      height: 6rem;
      min-width: 18rem;
      border-right: 1px solid #ffffff40;

      &__price__container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-left: 0.8rem;
        padding-right: 0.8rem;
      }

      &-title {
        font-size: 1.4rem;
        font-weight: 300;
        line-height: 1.7rem;
        padding-bottom: 1rem;
        padding-left: 0.8rem;
      }

      &-price {
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1.7rem;
      }

      &-increase {
        color: #46ff6f;
        font-size: 1.4rem;
        font-weight: bold;
        line-height: 1.7rem;
      }
    }
  }

  &__first__container {
    grid-column: 1 / -1;
    display: flex;
    flex-direction: row;
    justify-content: center;

    &-title {
      position: absolute;
      font-size: 9rem;
      padding-top: 9rem;
      color: white;
      text-align: center;
      width: 100%;
    }

    &-button {
      position: absolute;
      margin-top: 30rem;
      width: 24rem;
    }

    &-image {
      grid-column: 1 / -1;
      width: 100%;
      height: 100vh;
    }
  }

  &__second__container {
    display: flex;
    grid-column: 2 / 14;
    flex-direction: column;

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;

      &-logo {
        width: 36.5rem;
        padding-bottom: 4rem;
      }

      &-text {
        font-size: 1.8rem;
        line-height: 3.6rem;
        font-weight: 500;
        text-align: center;
        margin-left: 14rem;
        margin-right: 14rem;
      }

      &-title {
        font-size: 2.8rem;
        font-weight: 600;
        line-height: 5rem;
        text-align: center;
        padding-bottom: 3rem;
        color: #f4f8fb;
      }
    }

    &__features__content {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-top: 3.2rem;

      &-earth-image {
        margin-left: -10rem;
      }

      &-title {
        font-size: 2.4rem;
        font-weight: 600;
        line-height: 4.4rem;
        padding-bottom: 2.5rem;
      }

      &__item {
        display: flex;
        flex-direction: row;
        padding-bottom: 5rem;

        &-icon {
          padding-right: 3rem;
          padding-top: 0.5rem;
          cursor: pointer;
        }

        &-title {
          font-size: 3.2rem;
          font-weight: bold;
          line-height: 4.5rem;
          background: -webkit-linear-gradient(#46abff, #466aff);
          -webkit-background-clip: text;
          background-clip: text;
          padding-bottom: 4rem;
          -webkit-text-fill-color: transparent;
        }

        &-text {
          color: #f4f8fb;
          font-size: 2.3rem;
          font-weight: 600;
          line-height: 4.5rem;
        }
      }
    }
  }

  &__video_container {
    grid-column: 1 / -1;
    position: relative;
    &-image {
      grid-column: 1 / -1;
      width: 100%;
      height: 80vh;
      mask-image: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.28) 100%);
      -webkit-mask-image: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.28) 100%);
    }

    &__icon__container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      cursor: pointer;

      &-text {
        font-size: 3.2rem;
        font-weight: 600;
        line-height: 6.4rem;
      }
    }
  }

  &__third__container {
    grid-column: -1 / 1;
    grid-row: 3;
    background: linear-gradient(231.98deg, #174edc 0%, #0f257e 100%);

    &-mac__image {
      grid-row: 3;
      padding-top: 8.7rem;
      padding-bottom: 9.7rem;
      grid-column: 6 / -1;
      width: 100%;
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      grid-row: 3;
      grid-column: 2 / 6;

      &-title {
        font-size: 4.6rem;
        font-weight: bold;
        line-height: 6.4rem;
        padding-bottom: 2rem;
      }

      &-text {
        font-size: 1.8rem;
        line-height: 3.2rem;
        padding-bottom: 3rem;
      }

      &-button {
        background: white;
        color: black;
        font-size: 1.8rem;
        font-weight: 500;
        line-height: 2.1rem;
      }
    }
  }

  &__fifth__container {
    grid-column: -1 / 1;
    margin-bottom: -2rem;

    &__image__container {
      position: relative;
      display: flex;
      flex: 1;
      height: 60rem;
      overflow: hidden;
      &-image {
        position: absolute;
        bottom: 0;
        right: 0;
        margin-bottom: -30rem;
      }
    }

    &__text__container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;

      &-title {
        font-size: 4.6rem;
        font-weight: bold;
        line-height: 6.4rem;
        padding-bottom: 2rem;
      }
      &-text {
        font-size: 3.2rem;
        line-height: 3.4rem;
        padding-bottom: 3rem;
      }

      &-link {
        font-size: 2.4rem;
        font-weight: 600;
        line-height: 3.5rem;
        border-bottom: 3px solid #466aff;
        background: -webkit-linear-gradient(#46abff, #466aff);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        cursor: pointer;
      }
    }
  }

  &__fourth__container {
    display: grid;
    grid-column: 2 / 14;
    grid-template-columns: 1fr repeat(12, [col-start] 80px) 1fr;
    gap: 20px;

    &__content {
      grid-column: 2 / 6;
      grid-row: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &-title {
        font-size: 4.6rem;
        font-weight: bold;
        line-height: 6.4rem;
        padding-bottom: 2.4rem;
      }

      &-learn__more {
        color: #466aff;
        font-size: 2.4rem;
        font-weight: 600;
        line-height: 3.5rem;
        cursor: pointer;
        text-decoration: underline;
      }

      &-feature-text {
        font-size: 2rem;
        line-height: 3.4rem;
        padding-bottom: 2.4rem;
      }
    }

    &__images {
      grid-column: 8 / 14;
      grid-row: 1;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      &-earth {
        padding-right: 3rem;
      }
    }
  }

  &__footer {
    display: grid;
    grid-template-columns: 1fr repeat(12, [col-start] 80px) 1fr;
    gap: 20px;
    background: #111521;
    grid-column: -1 / 1;

    &__content {
      grid-column: 2 / 14;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &-title {
        font-size: 4.6rem;
        font-weight: bold;
        line-height: 6.4rem;
        padding-top: 8rem;
        padding-bottom: 3rem;
      }

      &-sign__up__btn {
        width: 20rem;
        margin-bottom: 4.3rem;
      }

      &-spacing {
        height: 4rem;
      }

      &-info__text {
        font-size: 1.2rem;
        line-height: 1.8rem;
        padding-bottom: 2.7rem;
      }

      &__pages__container {
        display: flex;
        flex-direction: row;
        padding-bottom: 3rem;

        &-text {
          font-size: 2rem;
          line-height: 3.4rem;
          padding-right: 2.4rem;
          cursor: pointer;
          color: white;
          text-decoration: none;
        }
      }
    }
  }
}
