.container {
  display: flex;
  flex-direction: row;
  position: relative;
  display: inline-block;
  text-align: center;
  background: transparent;
}

.container input {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  z-index: -1;
  outline: none;
  background-color: black;
  border-color: black;
  &:focus {
    outline: none;
    background-color: black;
  }
}

.container div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 63px;
  color: #fafafb;
  font-size: 17.5px;
  letter-spacing: 0.13px;
  line-height: 21px;
  text-align: center;
  text-justify: center;
  border: 1.25px solid #ffffff;
  border-radius: 12.5px;
  float: left;
  margin: 0 10px;
  cursor: text;
}

.container .active {
  border-color: #466fff;
}
