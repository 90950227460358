.select__number__container {
  display: flex;

  &-select {
    background-color: transparent;
    color: white;

    border: 1px solid #ffffff;
    border-radius: 10px;
    margin-right: 10px;
    padding: 10px;
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  &:focus {
    outline: none;
  }
  text-align-last: center;
}
