input[type='radio'] {
  margin: 0 1rem;
}

.radio-item {
  display: inline-block;
  position: relative;
  padding: 0 6px;
  margin: 0 10px;
}

.radio-item input[type='radio'] {
  display: none;
}

.radio-item label {
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.radio-item label:before {
  content: ' ';
  display: inline-block;
  position: relative;
  margin: 0 5px 0 0;
  width: 18px;
  height: 18px;
  border-radius: 18px;
  border: 1px solid white;
  background-color: transparent;
}

.radio-item input[type='radio']:checked + label:after {
  border-radius: 18px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 3px;
  left: 9px;
  content: ' ';
  display: inline-block;
  background: #466fff;
}

.radio-item input[type='radio']:checked + label::before {
  border: 1px solid #466fff;
}
