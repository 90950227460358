.land__container {
  border-radius: 2rem;
  background-color: #040508;
  margin-bottom: 2rem;
  margin-right: 2rem;
  max-width: 38rem;

  &:hover {
    cursor: pointer;
  }

  &__image__container {
    position: relative;
    height: 22rem;
    width: 38rem;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    overflow: hidden;

    &__content {
      object-fit: cover;
    }

    &-total__tiles {
      position: absolute;
      top: 0;
      right: 0;
      border-radius: 1.5rem;
      margin-top: 1.6rem;
      margin-right: 1.6rem;
      background-color: #ffffff30;
      padding: 0.7rem 1.4rem;
      font-size: 1.2rem;
      font-weight: 900;
      letter-spacing: -0.4px;
      line-height: 1.6rem;
    }
  }

  &__land__info {
    padding: 1.6rem;

    &-name {
      color: #f4f8fb;
      font-size: 2.2rem;
      font-weight: 600;
      letter-spacing: -0.51px;
      line-height: 2.6rem;
      padding-bottom: 1.2rem;
      overflow-wrap: break-word;
    }

    &__price__container {
      display: flex;
      flex-direction: row;
      padding-bottom: 1.8rem;

      &-current__price {
        color: #46ff6f;
        font-size: 2.2rem;
        font-weight: 600;
        letter-spacing: -0.61px;
        line-height: 2.6rem;
      }

      &-bought__value {
        color: #7d9aff;
        font-size: 2.2rem;
        font-weight: 600;
        letter-spacing: -0.61px;
        line-height: 2.6rem;
        padding-left: 3.2rem;
      }
    }

    &-coordinates {
      color: #f4f8fb;
      font-size: 2.2rem;
      font-weight: 600;
      letter-spacing: -0.51px;
      line-height: 2.6rem;
      padding-bottom: 0.6rem;
    }

    &-location {
      opacity: 0.5;
      color: #ffffff;
      font-size: 1.6rem;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 2.4rem;
    }
  }
}

.last__in__row {
  margin-right: unset;
}
