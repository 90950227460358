.header {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(12, [col-start] 8rem);
  grid-template-rows: 8rem;
  gap: 2rem;
}

.loggedInHeader {
  display: flex;
}

.headerLogo {
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
  padding-left: 1.5rem;
  padding-right: 5.2rem;
}
.headerContainer {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  grid-column: 1 / -1;
}

.appTitle {
  font-size: 18px;
  font-weight: 800;
  color: white;
  text-decoration: unset;
}

.navigator {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.authContainer {
  display: flex;
  align-items: center;
}

.user__details__container {
  display: flex;
  align-items: center;
  padding-right: 2.6rem;

  &__balance__container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 6.1rem;

    &__avatar__container {
      height: 4.4rem;
      width: 4.4rem;
      border-radius: 100%;
      background: linear-gradient(231.98deg, #31b33e 0%, #278c44 100%);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 1.1rem;

      &-text {
        font-size: 2.4rem;
      }
    }

    &-balance__text {
      font-size: 1.6rem;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 2rem;
    }

    &-add__credit {
      font-size: 1.4rem;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 1.7rem;
      color: white;
    }
  }

  &__user__info__container {
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;
    }

    &__avatar__container {
      height: 4.4rem;
      width: 4.4rem;
      border-radius: 100%;
      background-color: #3859cc;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 1.4rem;

      &-text {
        font-size: 1.6rem;
        font-weight: 800;
        letter-spacing: 0;
        line-height: 2.7rem;
      }

      &-profile__photo {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        object-fit: fill;
      }
    }
  }
}

.link {
  text-decoration: unset;
  opacity: 0.5;
  font-size: 1.4rem;
  line-height: 1.7rem;
  font-weight: 300;
  color: white;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  margin-right: 2rem;
  padding: 0.8rem 2.5rem;
}

.activeLink {
  background-color: #3859cc;
  opacity: 1;
}

.registerLink {
  text-decoration: unset;
  color: white;
  font-weight: bold;
  padding-right: 26px;
  font-size: 15;
}
