.land__screen {
  display: grid;
  justify-content: center;
  background-color: rgba(19, 25, 42, 0.8);
  grid-template-columns: 1fr repeat(12, [col-start] 80px) 1fr;
  min-height: 100%;
  gap: 20px;

  &__main__container {
    grid-column: 2 / -2;
    padding-top: 3.2rem;

    &__basic__info__container {
      display: flex;
      flex-direction: row;
      padding-bottom: 2.4rem;

      &__image__container {
        flex: 1;
        position: relative;
        padding-right: 2rem;
        width: 100%;
        height: 33rem;

        &-image {
          width: 100%;
          height: 100%;
          border-radius: 2rem;
        }

        &-text {
          position: absolute;
          bottom: 0;
          left: 0;
          font-size: 2.2rem;
          font-weight: 600;
          letter-spacing: -0.51px;
          padding: 2.4rem;
        }
      }

      &__content {
        flex: 1;
        border-radius: 2rem;
        background-color: black;

        &__row {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 1.6rem;
          border-bottom: 1px solid #2a3554;

          &-key {
            opacity: 0.4;
            font-size: 1.4rem;
            letter-spacing: -0.47px;
            width: 15.5rem;
          }

          &-value {
            font-size: 1.4rem;
            font-weight: 600;
            letter-spacing: -0.47px;
          }
        }
      }
    }

    &__price__container {
      display: flex;
      flex-direction: row;
      height: 40rem;

      &__modal {
        &-text {
          text-align: center;
          color: #f4f8fb;
          padding-bottom: 2rem;
          font-size: 1.6rem;
        }

        &-input {
          input {
            height: 4rem;
          }
          input.dirty + .placeholder,
          input:focus + .placeholder,
          input:not(:placeholder-shown) + .placeholder {
            top: -0.5px;
            font-size: 12px;
            background-color: rgb(19, 25, 42);
            color: #466fff;
          }
        }

        &__button__container {
          display: flex;
          flex-direction: row;
          padding-top: 2rem;

          &-btn {
            height: 3.2rem;
            color: #f4f8fb;
            font-size: 1.4rem;
            line-height: 1.7rem;
            border: 1.8px solid #2c5ad3;
          }
        }
      }

      &__history {
        flex: 1;
        position: relative;
        border-radius: 2rem;
        background-color: black;
        margin-right: 2rem;

        &-chart__mode {
          position: absolute;
          top: 2rem;
          right: 2rem;
          display: flex;
          border: 1px solid #44444f;
          border-radius: 8px;

          &-text {
            color: #b5b5be;
            font-size: 1.2rem;
            letter-spacing: 0.2px;
            padding: 0.4rem 0.8rem;
            cursor: pointer;
            width: 7rem;
            text-align: center;
          }

          &-selected__text {
            border-radius: 8px;
            background-color: #466fff;
            font-size: 1.2rem;
            letter-spacing: 0.2px;
            padding: 0.4rem 0.8rem;
            cursor: pointer;
            margin: 1px;
            width: 7rem;
            text-align: center;
          }
        }
      }

      &__info {
        flex: 1;

        &__content {
          display: flex;
          flex-direction: row;
          padding-bottom: 2.5rem;

          &-input {
            input.dirty + .placeholder,
            input:focus + .placeholder,
            input:not(:placeholder-shown) + .placeholder {
              top: -0.5px;
              font-size: 12px;
              background-color: rgba(19, 25, 42, 0.8);
              color: #466fff;
            }
          }

          &-text {
            opacity: 0.36;
            font-size: 1.2rem;
            letter-spacing: -0.4px;
            line-height: 1.5rem;
          }

          &-value {
            font-size: 1.6rem;
            font-weight: 600;
            letter-spacing: -0.53px;
            line-height: 2rem;
          }

          &-value__percentage {
            display: flex;
            color: #2fae3f;
            font-size: 1.2rem;
            font-weight: 600;
            letter-spacing: -0.4px;
            line-height: 1.5rem;
            padding-left: 0.8rem;
            align-items: flex-end;
          }

          &-bought__value {
            flex: 1;
            display: flex;
            flex-direction: row;
            border: 1px solid #ffffff;
            border-radius: 2rem;
            padding: 2.2rem;
            margin-right: 2rem;
            align-items: center;
          }

          &-price__value {
            flex: 1;
            border: 1px solid #466fff;
            display: flex;
            flex-direction: row;
            border-radius: 20px;
            padding: 2.2rem;
            margin-right: 2rem;
            align-items: center;
          }

          &-market__value {
            flex: 1;
            border: 1px solid #2fae3f;
            display: flex;
            flex-direction: row;
            border-radius: 20px;
            padding: 2.2rem;
            align-items: center;

            &-content {
              display: flex;
              flex-direction: row;
            }
          }
        }
      }
    }

    &__pagination__container {
      display: flex;
      justify-content: flex-end;
      padding-bottom: 2rem;
    }
  }
}
