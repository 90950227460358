.auth__screen {
  display: flex;
  justify-content: space-between;
  max-height: 100vh;

  &__container {
    position: absolute;
    align-self: center;
    left: 7.7rem;
    color: #fff;

    &-welcome__to__message {
      color: #466fff;
      font-size: 2.4rem;
      font-weight: 500;
      letter-spacing: 2px;
      line-height: 2.4rem;
      padding-bottom: 1.6rem;
    }

    &-logo {
      width: 39rem;
      padding-bottom: 4rem;
    }

    &-title {
      color: #f4f8fb;
      font-size: 6rem;
      font-weight: bold;
      letter-spacing: -1.67px;
      line-height: 7.2rem;
      margin: unset;
      padding-bottom: 6.1rem;
    }

    &-input {
      margin-bottom: 1.6rem;
      max-width: 48rem;
    }

    &-text {
      color: #f4f8fb;
      font-size: 2rem;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 2.4rem;
      padding-bottom: 3.8rem;
    }

    &-verification__text {
      color: #f4f8fb;
      font-size: 2rem;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 2.4rem;
      padding-bottom: 1.6rem;
    }

    &-button {
      margin-top: 9.4rem;
      max-width: 48rem;
    }

    &-verification__button {
      margin-top: 7rem;
      max-width: 48rem;
    }

    &-code__text {
      margin-top: 4.3rem;
      margin-bottom: 7rem;
    }

    &-try__again__text {
      text-decoration: underline;
      color: #5c86ff;
      font-size: 1.6rem;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 2rem;
      cursor: pointer;
    }

    &-edit__photo__text {
      text-decoration: underline;
      color: #f4f8fb;
      font-size: 1.5rem;
      font-weight: 600;
      letter-spacing: -0.42px;
      line-height: 1.9rem;
      margin-top: 1.6rem;
      cursor: pointer;
    }

    &__avatar__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 48rem;

      &-image__input {
        display: none;
      }

      &-avatar {
        height: 20rem;
        width: 20rem;
        border-radius: 100%;
        background-color: #3859cc;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
      }

      &-profile__photo {
        max-width: 100%;
        object-fit: fill;
      }

      &-avatar__text {
        color: #f4f8fb;
        font-size: 6rem;
        font-weight: bold;
        letter-spacing: -1.67px;
        line-height: 7.2rem;
      }
    }

    &__name_container {
      display: flex;
      flex-direction: row;

      &-input {
        margin-bottom: 1.6rem;
        width: 23rem;
      }

      &-input__left {
        margin-bottom: 1.6rem;
        margin-right: 2rem;
        width: 23rem;
      }
    }

    &__additional__info__container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 1.6rem;
      width: 48rem;

      &-text {
        color: #f4f8fb;
        font-size: 1.6rem;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 2rem;
      }

      &-link {
        color: #5c86ff;
        font-size: 1.6rem;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 2rem;
        padding-left: 1.1rem;
      }
    }
  }

  &__image__container {
    display: flex;
    justify-content: flex-end;
    flex: 1;
  }
}
