.marketplace__screen {
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr repeat(12, [col-start] 80px) 1fr;
  width: 100%;
  min-height: 100%;
  gap: 20px;
  background-color: #151b2e;

  &__select__container {
    display: flex;
    flex-direction: row;
  }

  &__main__container {
    grid-column: 2 / -2;
  }

  &__pagination__container {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 2rem;
  }
  &-title {
    margin-top: 3.2rem;
    font-size: 3.2rem;
    letter-spacing: -0.89;
    color: '#F4F8FB';
  }

  &__table {
    max-width: 118rem;
    margin-bottom: 4rem;
    margin-top: 2.4rem;
  }

  &__header {
    width: 100%;
    background-color: #1b233b;
    display: flex;
    height: 4.6rem;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    align-items: center;
    padding: 1.5rem 2.1rem 1.5rem 2.1rem;
  }

  &__footer {
    width: 100%;
    background-color: #040508;
    height: 4.6rem;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  &__row {
    height: 10rem;
    width: 100%;
    background-color: #040508;
    display: flex;
    align-items: center;
    padding: 1.5rem 2.1rem 1.5rem 2.1rem;
    border-bottom: 2px solid #1b233b;
  }

  &__col {
    align-items: center;

    &-title {
      font-size: 1.4rem;
      line-height: 1.7rem;
      text-transform: uppercase;
      letter-spacing: 0.1px;
      color: #92929d;
    }

    &-value {
      font-size: 1.4rem;
      line-height: 1.7rem;
      letter-spacing: 0.1px;
      color: #fff;
    }

    &-price {
      font-size: 1.6rem;
      line-height: 2rem;
      color: #7599ff;
    }

    &-market-value {
      font-size: 1.6rem;
      line-height: 2rem;
      color: #30ad40;
    }

    &-1 {
      flex: 1;
    }

    &-2 {
      flex: 2;
    }

    &-3 {
      flex: 3;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &-4 {
      flex: 3;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &-5 {
      flex: 4;
    }

    &-6 {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 15rem;
      height: 100%;

      &-btn {
        height: 3rem;
        font-size: 1.4rem;
        line-height: 1.7rem;
      }

      &-black-btn {
        background-color: #040508;
        background: none;
        border: 1.8px solid #3859cc;
      }
    }
  }
}
