.select__container {
  position: relative;
  display: flex;
  flex-direction: row;
  border-radius: 1rem;
  background-color: #040508;
  margin-top: 1rem;
  margin-right: 1rem;

  label {
    color: #92929d;
    font-size: 1.2rem;
    letter-spacing: 0.09px;
    line-height: 1.5rem;
    padding: 1rem;
    width: 7rem;
  }

  input {
    flex: 1;
    background-color: transparent;
    border: none;
    border-right: 1px solid #14171f;
    color: #d5d5dc;
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: 0.1px;

    &:focus {
      outline: none;
    }
  }

  &-clicked {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  &__options {
    position: absolute;
    background-color: #040508;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    color: #d5d5dc;
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: 0.1px;
    width: 100%;
    top: 3.5rem;
    z-index: 100;
    max-height: 21rem;
    overflow-y: scroll;

    &-option {
      padding: 1rem;

      &:hover {
        color: #3859cc;
      }
    }
  }

  img {
    padding: 0 0.7rem;

    &:hover {
      cursor: pointer;
    }
  }
}
