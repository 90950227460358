.add__credit__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #151b2e;
  height: calc(100vh - 9rem);

  &__content {
    width: 50rem;

    &__credit__info {
      border-radius: 1.5rem;
      background-color: #040508;
      margin: 2rem 0;
      display: flex;
      justify-content: center;
      padding: 2rem 0;

      &-amount {
        color: #35c249;
        font-size: 2.2rem;
        font-weight: 600;
        letter-spacing: -0.61px;
        line-height: 2.6rem;
      }

      &-credit {
        opacity: 0.2;
        color: #ffffff;
        font-size: 1.4rem;
        font-weight: 600;
        letter-spacing: -0.39px;
        line-height: 1.7rem;
        align-self: flex-end;
        padding-left: 4px;
      }
    }

    &-input {
      margin-bottom: 1.6rem;
      input.dirty,
      input:focus,
      input:not(:placeholder-shown) {
        border: 1px solid #2ea940;
      }

      input.dirty + .placeholder,
      input:focus + .placeholder,
      input:not(:placeholder-shown) + .placeholder {
        background-color: #151b2e;
        color: #2ea940;
      }
    }

    &-button {
      border: 1px solid #2ea940;
      background: transparent;
      font-size: 1.4rem;
      font-weight: 600;
      line-height: 1.7rem;
    }
  }
}
