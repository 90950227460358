.user__info__container {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__avatar__container {
    border-radius: 100%;
    height: 3.2rem;
    width: 3.2rem;
    background-color: #3859cc;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1.2rem;

    &-text {
      font-size: 1.2rem;
      font-weight: 800;
      line-height: 2rem;
    }

    &-profile__photo {
      width: 100%;
      height: 100%;
      border-radius: 100%;
      object-fit: fill;
    }
  }

  &__name__container {
    display: flex;
    flex-direction: row;

    &-text {
      font-size: 1.4rem;
      font-weight: 600;
      letter-spacing: -0.47px;
      line-height: 1.7rem;
    }
  }
}
