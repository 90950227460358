.pagination-container {
  display: flex;

  .pagination-item {
    display: flex;
    align-items: center;
    color: #92929d;
    letter-spacing: 0.1px;
    font-size: 1.4rem;
    padding: 0 1rem;

    &.dots:hover {
      cursor: default;
    }
    &:hover {
      cursor: pointer;
    }

    &.selected {
      color: #2c5ad3;
    }
  }

  .arrow {
    display: flex;
    border: 1px solid #44444f;
    border-radius: 8px;
    align-items: center;
    cursor: pointer;

    &.disabled {
      pointer-events: none;
    }
  }
}

.icon {
  padding: 0.4rem;
}
